import React, { Component } from 'react';
import ContentBox from '../components/FormArea/ContentBox';
import Input from '../components/Inputs/Input';
import Button from '../components/Button';
import settings from '../../settings';
import swal from '@sweetalert/with-react';

class ExportStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: 0,
      to: 0,
      loading: false,
    };
  }

  exportStatistics = () => {
    this.setState({ loading: true });
    const url = `${settings.statistic.getExportStatistics}?from=${this.state.from}&to=${this.state.to}`;
    fetch(url, {
      method: 'GET',
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Statistik.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({ loading: false });
        swal('Erfolgreich', 'Statistik erfolgreich exportiert.', 'success');
      })
      .catch((err) => {
        console.error('Error downloading list:', err);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <b>Export Statistik</b>
          </div>
        </div>
        <div className="exportStatistic-content-area">
          <ContentBox title="Excel Statistik File Exportieren">
            <Input
              type="date"
              validator={[
                {
                  required: true,
                  error: 'Pflichtfeld ist nicht ausgefüllt.',
                },
              ]}
              onChange={(value) => {
                return this.setState({ from: value });
              }}
              label="Von:"
              name="Chiffre"
            />
            <Input
              type="date"
              validator={[
                {
                  required: true,
                  error: 'Pflichtfeld ist nicht ausgefüllt.',
                },
              ]}
              onChange={(value) => {
                return this.setState({ to: value });
              }}
              label="Bis:"
              name="FirstName"
            />
            <div className="exportStatistic-button-area">
              <Button
                type="primary"
                onClick={() => {
                  return this.exportStatistics();
                }}
              >
                {this.state.loading ? 'Loading...' : 'Exportieren'}
              </Button>
            </div>
          </ContentBox>
        </div>
      </div>
    );
  }
}

export default ExportStatistics;
