import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import Loader from '../LoaderSmall';
import validate from '../../logic/validator';

const customStyles = {
  menu: () => {
    const position = 'absolute';
    const background = '#ffffff';
    const width = '100%';
    const marginTop = '10px';
    const boxShadow = '0px 3px 9px rgba(0, 0, 0, 0.16)';
    const padding = '5px 0';
    const borderRadius = '5px';
    const border = '1px solid rgba(80, 97, 131, 0.5)';
    const zIndex = '999999';

    return {
      position,
      boxShadow,
      width,
      background,
      marginTop,
      padding,
      borderRadius,
      border,
      zIndex,
    };
  },
};

class Dropdown extends Component {
  static displayName = 'Dropdown';
  constructor(props) {
    super(props);

    if (this.props.options) {
      this.value = this.getDefaultValue();
    } else {
      this.value = null;
    }

    this.state = {
      error: '',
      value: this.getValue(this.value),
    };
  }

  componentDidMount() {
    if (
      this.props.onInitialized &&
      typeof this.props.onInitialized === 'function'
    ) {
      this.props.onInitialized(this.value);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.value = this.getDefaultValue();
      this.setState({
        value: this.getValue(this.value),
      });
    }
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.value = this.getDefaultValue();
      this.setState({
        value: this.getValue(this.value),
      });
    }
  }

  /**
   * This function exists for "Form"
   * Do not delete unless you delete "Form"!
   */
  validate = async () => {
    const validator = await validate(this.value, this.props.validator);
    this.setState({
      error: validator.error,
    });
    return validator.isValid;
  };

  getDefaultValue = () => {
    let { defaultValue } = this.props;

    if (this.props.isMulti) {
      const fixedOptions = [];
      this.props.options.forEach((option) => {
        if (option.isFixed) {
          fixedOptions.push(option.value);
        }
      });

      defaultValue = [
        ...fixedOptions,
        ...(defaultValue
          ? defaultValue.filter((value) => {
              return !fixedOptions.includes(value);
            })
          : []),
      ];
    }

    if (
      defaultValue === null ||
      defaultValue === undefined ||
      defaultValue.length === 0
    ) {
      defaultValue = null;
    }

    return defaultValue;
  };

  getValue = (selection) => {
    let hasDefaultInOptions = false;
    if (selection !== undefined && selection !== null) {
      if (typeof selection === 'object') {
        hasDefaultInOptions = selection.some((item) => {
          return this.props.options.find((option) => {
            return option.value === item;
          });
        });
      } else {
        hasDefaultInOptions = this.props.options.find((option) => {
          return option.value === selection;
        });
      }
    }

    if (!hasDefaultInOptions) {
      return null;
    }

    if (typeof selection === 'object') {
      return selection.map((item) => {
        return {
          ...this.props.options.find((option) => {
            return option.value === item;
          }),
          value: item,
        };
      });
    }
    if (selection !== undefined && selection !== null) {
      return {
        ...this.props.options.find((option) => {
          return option.value === selection;
        }),
        value: selection,
      };
    }

    return null;
  };

  onChange = (selection, { action, removedValue }) => {
    if (
      (action === 'remove-value' || action === 'pop-value') &&
      removedValue.isFixed
    ) {
      return;
    }

    if (action === 'clear') {
      const clearedSelection = this.props.options
        .filter((option) => {
          return option.isFixed;
        })
        .map((option) => {
          return option.value;
        });

      if (clearedSelection && clearedSelection.length > 0) {
        this.value = clearedSelection;
      } else {
        this.value = null;
      }

      this.setState({
        value: this.getValue(this.value),
      });
      this.props.getSelected(this.value);
      return;
    }

    if (!selection) {
      this.value = null;
      this.setState({
        value: this.getValue(this.value),
      });
      this.props.getSelected(this.value);
      return;
    }

    if (selection.length) {
      this.value = selection.map((item) => {
        return item.value;
      });
      this.setState({
        value: this.getValue(this.value),
      });
      this.props.getSelected(this.value);
    } else {
      this.value = selection.value;
      this.setState({
        value: this.getValue(this.value),
      });
      this.props.getSelected(this.value);
    }
    this.validate();
  };

  render() {
    return (
      <div
        className={`Dropdown ${
          this.props.fullWidth ? 'Input--fullWidth' : ''
        } ${this.props.isDisabled ? 'Dropdown--is-disabled' : ''}`}
      >
        {this.props.label && (
          <label className="Dropdown-label" htmlFor={this.props.name}>
            {this.props.label}
            {this.props.info && (
              <div className="Dropdown-info">
                <FontAwesomeIcon icon={faInfoCircle} />
                <span className="tooltiptext">{this.props.info}</span>
              </div>
            )}
          </label>
        )}
        {this.props.options && this.props.options.length > 0 ? (
          <>
            <ReactSelect
              noOptionsMessage={() => {
                return 'Nicht vorhanden...';
              }}
              placeholder="Bitte wählen..."
              className="Dropdown-input"
              classNamePrefix="Dropdown"
              formatGroupLabel="Ok"
              {...this.props}
              styles={customStyles}
              onChange={this.onChange}
              value={this.state.value}
            />
            <FontAwesomeIcon className="Dropdown-Caret" icon={faCaretDown} />
          </>
        ) : (
          <Loader />
        )}

        {this.state.error && (
          <span className="Dropdown-error">{this.state.error}</span>
        )}
      </div>
    );
  }
}

Dropdown.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    ),
  ]),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    }).isRequired,
  ),
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  getSelected: PropTypes.func,
  onInitialized: PropTypes.func,
  validator: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      error: PropTypes.string.isRequired,
    }),
  ),
};

Dropdown.defaultProps = {
  fullWidth: false,
  label: '',
  info: '',
  options: null,
  validator: [],
  getSelected: () => {
    return null;
  },
  onInitialized: () => {
    return null;
  },
};

export default Dropdown;
