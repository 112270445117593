import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
  faInfoCircle,
  faEnvelopeOpenText,
  faExternalLink,
} from '@fortawesome/pro-regular-svg-icons';
import validate from '../../logic/validator';

import PasswordValidator from '../PasswordValidator';

export default class Input extends React.Component {
  static displayName = 'Input';
  constructor(props) {
    super(props);
    this.value = props.defaultValue || props.value || null;
    this.state = {
      error: '',
      value: this.value,
      showPassword: false,
      passwordValid: false,
    };
  }
  /**
   * This function exists for "Form"
   * Do not delete unless you delete "Form"!
   */
  validate = async () => {
    const { value } = this;
    const validator = await validate(value, this.props.validator);

    if (!validator.isValid) {
      this.setState({
        error: validator.error,
      });
      return false;
    }

    if (value && this.props.passwordValidator && !this.state.passwordValid) {
      return false;
    }

    this.setState({
      error: '',
    });

    return true;
  };

  handleChange = () => {
    let { value } = this.input;
    value = value.replace(/\n/g, '<br />');
    this.value = value;

    this.validate();

    if (this.props.passwordValidator) {
      this.setState({
        value,
      });
    }

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  _passwordValid = (isValid) => {
    this.setState({
      passwordValid: isValid,
    });
  };

  render() {
    if (this.props.type === 'textarea') {
      return (
        <div
          className={`Input-wrapper ${
            this.props.fullWidth ? 'Input--fullWidth' : ''
          }`}
        >
          <div className="Input">
            {this.props.label && (
              <label className="Input-label" htmlFor={this.props.name}>
                {this.props.label}
                {this.props.info && (
                  <div className="Input-info">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <span className="tooltiptext">{this.props.info}</span>
                  </div>
                )}
              </label>
            )}
            <textarea
              className={`Input-field ${this.props.className}`}
              id={this.props.name}
              name={this.props.name}
              autoComplete={this.props.autoComplete}
              value={this.props.value}
              placeholder={this.props.placeholder}
              defaultValue={this.props.defaultValue?.replace(/<br \/>/g, '\n')}
              onKeyUp={this.handleChange}
              disabled={this.props.isDisabled}
              ref={(node) => {
                this.input = node;
                this.props.innerRef(node);
              }}
            />
            {this.state.error ? (
              <span className="Input-error">{this.state.error}</span>
            ) : null}
          </div>
        </div>
      );
    }

    if (this.props.type === 'password') {
      return (
        <div
          className={`Input-wrapper ${
            this.props.fullWidth ? 'Input--fullWidth' : ''
          }`}
        >
          <div
            className={`Input Input-password ${
              this.props.passwordShowButton ? 'Input-passwordShowButton' : null
            } `}
          >
            {this.props.label && (
              <label className="Input-label" htmlFor={this.props.name}>
                {this.props.label}
                {this.props.info && (
                  <div className="Input-info">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <span className="tooltiptext">{this.props.info}</span>
                  </div>
                )}
              </label>
            )}
            {this.props.passwordShowButton && (
              <button
                className="Input-showPasswordButton"
                onClick={() => {
                  this.setState({
                    showPassword: !this.state.showPassword,
                  });
                }}
              >
                <div className="Input-showPasswordButton-text">
                  <FontAwesomeIcon
                    icon={this.state.showPassword ? faEyeSlash : faEye}
                  />
                  <p className="Input-showPasswordButton-label">
                    {this.state.showPassword ? 'Verbergen' : 'Anzeigen'}
                  </p>
                </div>
              </button>
            )}
            <input
              className={`Input-field ${this.props.className}`}
              id={this.props.name}
              name={this.props.name}
              autoComplete={this.props.autoComplete}
              value={this.props.value}
              placeholder={this.props.placeholder}
              defaultValue={this.props.defaultValue}
              onKeyUp={this.handleChange}
              type={this.state.showPassword ? 'text' : 'password'}
              disabled={this.props.isDisabled}
              ref={(node) => {
                this.input = node;
                this.props.innerRef(node);
              }}
            />
          </div>
          {this.props.passwordValidator && (
            <PasswordValidator
              value={this.state.value}
              onChangeValid={this._passwordValid}
            />
          )}
        </div>
      );
    }

    if (
      this.props.type === 'date' ||
      this.props.type === 'time' ||
      this.props.type === 'number'
    ) {
      return (
        <div
          className={`Input-wrapper ${
            this.props.fullWidth ? 'Input--fullWidth' : ''
          }`}
        >
          <div className="Input">
            {this.props.label && (
              <label className="Input-label" htmlFor={this.props.name}>
                {this.props.label}
                {this.props.info && (
                  <div className="Input-info">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <span className="tooltiptext">{this.props.info}</span>
                  </div>
                )}
              </label>
            )}
            <input
              className={`Input-field ${this.props.className}`}
              id={this.props.name}
              name={this.props.name}
              value={this.props.value}
              autoComplete={this.props.autoComplete}
              placeholder={this.props.placeholder}
              defaultValue={this.props.defaultValue}
              onChange={this.handleChange}
              type={this.props.type}
              disabled={this.props.isDisabled}
              ref={(node) => {
                this.input = node;
                this.props.innerRef(node);
              }}
            />
            {this.state.error ? (
              <span className="Input-error">{this.state.error}</span>
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <div
        className={`Input-wrapper ${
          this.props.fullWidth ? 'Input--fullWidth' : ''
        }`}
      >
        <div className="Input">
          {this.props.label && (
            <label className="Input-label" htmlFor={this.props.name}>
              {this.props.label}
              {this.props.info && (
                <div className="Input-info">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <span className="tooltiptext">{this.props.info}</span>
                </div>
              )}
              {this.props.linkTo && (
                <a
                  className="linktTo"
                  rel="noreferrer"
                  href={`http://${this.props.defaultValue}`}
                  target="_blank"
                >
                  <div className="Input-info">
                    <FontAwesomeIcon fixedWidth icon={faExternalLink} />
                    <span className="tooltiptext">{this.props.linkTo}</span>
                  </div>
                </a>
              )}
              {this.props.mailTo && (
                <>
                  <a
                    className="mailTo"
                    rel="noreferrer"
                    href={`mailto:${this.props.defaultValue}`}
                  >
                    <div className="Input-info">
                      <FontAwesomeIcon fixedWidth icon={faEnvelopeOpenText} />
                      <span className="tooltiptext">{this.props.mailTo}</span>
                    </div>
                  </a>
                </>
              )}
            </label>
          )}
          <input
            className="Input-field"
            id={this.props.name}
            name={this.props.name}
            value={this.props.value}
            autoComplete={this.props.autoComplete}
            placeholder={this.props.placeholder}
            defaultValue={this.props.defaultValue}
            onKeyUp={this.handleChange}
            type={this.props.type}
            disabled={this.props.isDisabled}
            ref={(node) => {
              this.input = node;
              this.props.innerRef(node);
            }}
            readOnly={this.props.readonly}
          />
          {this.state.error ? (
            <span className="Input-error">{this.state.error}</span>
          ) : null}
        </div>
      </div>
    );
  }
}

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  info: PropTypes.string,
  name: PropTypes.string.isRequired,
  validator: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      error: PropTypes.string.isRequired,
    }),
  ),
  passwordValidator: PropTypes.bool,
  passwordShowButton: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  innerRef: PropTypes.func,
  readonly: PropTypes.bool,
};

Input.defaultProps = {
  validator: [],
  fullWidth: false,
  className: '',
  label: '',
  info: '',
  type: 'text',
  placeholder: '',
  onChange: () => {
    return null;
  },
  innerRef: () => {
    return null;
  },
  readonly: false,
};
